import { Grid, MenuItem, Paper, TableContainer } from "@mui/material";
import { AddButton, SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { $loginUserStore } from "../../../../../store/ResponseData";
import EditCompletedWork from "./EditCompletedWork";
import Table from "../../../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { formatDate } from "../../../../../utils/utils";
import { CompletedWork } from "../../../../../types/completedWorks";

const CompletedWorks = observer(() => {
    const {
        completedWorkPage,
        completedWorkRowsPerPage,
        completedWorkSort,
        contractDetail,
        completedWorkSearch,
        completedWorkList,
        completedWorkLoadingTable,
    } = $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getCompletedWorkList(
            String(contractDetail?.guid),
            completedWorkPage + 1,
            completedWorkSort.by,
            completedWorkSort.order,
            completedWorkRowsPerPage,
            completedWorkSearch
        );
    }, [
        contractDetail?.guid,
        completedWorkPage,
        completedWorkRowsPerPage,
        completedWorkSort.by,
        completedWorkSort.order,
        completedWorkSearch,
    ]);

    const columns: MRT_ColumnDef<CompletedWork>[] = [
        {
            accessorKey: "id",
            header: "№ п/п",
            size: 100,
            enableSorting: false,
            Cell: ({ row }) => <div>{completedWorkRowsPerPage * completedWorkPage + row.index + 1}</div>,
        },
        {
            accessorKey: "period",
            accessorFn: (row) => formatDate(row.start, "d.m.y") + " - " + formatDate(row.end, "d.m.y"),
            header: "Период",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "planned_cost",
            header: "Стоимость план (договор)",
            size: 300,
            enableSorting: false,
        },
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            if (key !== "status" && key !== "action") {
                $contractsDataStore.setCompletedWorkSort({
                    by: key,
                    order: completedWorkSort.order === "asc" ? "desc" : "asc",
                });
            }
        },
        [completedWorkSort]
    );

    const handleChangePage = (v: number) => {
        $contractsDataStore.setCompletedWorkPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setCompletedWorkRowsPerPage(v);
        $contractsDataStore.setCompletedWorkPage(0);
    };

    const handleEditCompletedWork = () => {
        $contractsDataStore.setEditCompletedWork(true);
    };

    return (
        <>
            <Grid container justifyContent={$loginUserStore.isAdmin() ? "space-between" : "flex-end"}>
                {$loginUserStore.isAdmin() ? (
                    <AddButton
                        onClick={handleEditCompletedWork}
                        style={{
                            margin: 0,
                        }}
                    />
                ) : null}
                <SearchField
                    value={completedWorkSearch}
                    callback={(value) => $contractsDataStore.setCompletedWorkSearch(value)}
                    callbackTimeout={700}
                    style={{
                        width: 300,
                    }}
                />
            </Grid>
            <TableContainer
                component={Paper}
                style={{
                    margin: "15px 0 0",
                }}
            >
                <Table
                    data={completedWorkList?.items}
                    columns={columns}
                    loading={completedWorkLoadingTable}
                    sortProps={completedWorkSort}
                    pageIndex={completedWorkPage}
                    pageSize={completedWorkRowsPerPage}
                    changeTableSort={changeTableSort}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={completedWorkList?.pagination.total! ?? 0}
                    enableRowActions={$loginUserStore.isAdmin()}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        <MenuItem
                            onClick={() => {
                                $contractsDataStore.setCurrentCompletedWorkGuid(row.original?.guid ?? "");
                                handleEditCompletedWork();
                                closeMenu();
                            }}
                        >
                            Изменить
                        </MenuItem>,
                    ]}
                />
            </TableContainer>
            {$contractsDataStore.editCompletedWork && <EditCompletedWork />}
        </>
    );
});
export default CompletedWorks;
