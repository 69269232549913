import axios from '../axios';
import Notification from '../components/ui/Notification';
import { AddMemberToDevice } from '../types/addMemberToDevice';
import {
    Device,
    Devices,
} from '../types/device';

export async function getDevicesList(
    page: number,
    orderBy: string,
    direction: string,
    pageSize: number,
    search: string
) {
    let data = {
        page: page,
        orderBy: orderBy,
        direction: direction,
        pageSize: pageSize,
        search: search,
    };
    let config = {
        method: "post",
        url: "/UserService/Devices_list",
        data: data,
    };
    try {
        const response = await axios.request(config);

        let result: Devices = response.data.data;

        if (!result) {
            return;
        }

        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }

        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить список устройств',
            type: 'error',
        });
    }
}

export async function newDeviceCreate(form: Device) {
    let data = form;
    let config = {
        method: "post",
        url: "/UserService/Devices_save",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newDevice: Device = response.data.data;

        if (
            !newDevice &&
            (
                response.data.error.message === "Данный идентификатор уже существует"
                || response.data.error.message === "Данный идентификатор прикреплен к другому устройству"
            )
        ) {
            return response.data.error.message;
        }

        if (!newDevice) {
            Notification({
                title: 'Не удалось создать новое устройство',
                type: 'error',
            });
            return;
        }
        return newDevice;
    } catch (error: any) {
        Notification({
            title: 'Не удалось создать новое устройство',
            type: 'error',
        });
    }
}

export async function attachDevice(
    form: AddMemberToDevice
) {
    let data = form;
    let config = {
        method: "post",
        url: "/UserService/Devices_attach",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newDevice: Device = response.data.data;
        if (!newDevice) {
            return response.data.error ?? null
        }
        return newDevice;
    } catch (error: any) {
        Notification({
            title: 'Не удалось закрепить сотрудника за устройством',
            type: 'error',
        });
    }
}

export async function detachDevice(
    form: AddMemberToDevice
) {
    let data = form;
    let config = {
        method: "post",
        url: "/UserService/Devices_detach",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const newDevice: Device = response.data.data;
        if (!newDevice) {
            return response.data.error ?? null
        }
        return newDevice;
    } catch (error: any) {
        Notification({
            title: 'Не удалось открепить сотрудника от устройства',
            type: 'error',
        });
    }
}

export async function exportDevices() {
    let config = {
        method: "get",
        url: "/UserService/Devices_export",
    };

    try {
        const response = await axios.request(config);

        return response;
    } catch (error: any) {
        Notification({
            title: 'Не удалось выгрузить устройства',
            type: 'error',
        });
    }
}

export async function checkImei(imei: string) {
    let data = { imei };
    let config = {
        method: "post",
        url: "/UserService/Devices_checkImei",
        data
    };

    try {
        const response = await axios.request(config);
        if (!response) return;

        return response.data.body;
    } catch (error: any) {
        Notification({
            title: "Не удалось проверить IMEI устройства",
            type: 'error'
        });
    }
}
