import { Grid } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { saveUserRoles } from "../../../api-actions/MembersApi";
import { $membersDataStore } from "../../../store/MembersStore";
import { Role } from "../../../types/member";
import Notification from "../../ui/Notification";
import CustomTooltip from "../../ui/Tooltip";
import AutocompleteMultiple from "../../ui/AutocompleteMultiple";
import Dialog from "../../ui/dialog/Dialog";

const EditRoleDialog = observer(() => {
    const { memberData, roleList } = $membersDataStore;

    const [strRoles, setStrRoles] = useState("");
    const [selectedRoles, setSelectedRoles] = useState<[] | Role[]>(memberData.roles ? memberData.roles.items : []);

    const handleRolesSelect = useCallback((roles: Role[]) => {
        const strOfGuid = roles.map((item) => item.guid).join(",");
        setStrRoles(strOfGuid);
        setSelectedRoles(roles);
    }, []);

    const handleSubmit = async () => {
        $membersDataStore.setLoading(true);

        const roleInfo = {
            user: memberData.guid,
            role: strRoles,
        };
        await saveUserRoles(roleInfo).then((resp) => {
            $membersDataStore.setOnEditRoleDialog(false);
            Notification({
                title: "Роль сохранена",
                type: "success",
                duration: 30000,
            });
        });
        $membersDataStore.setLoading(false);
        $membersDataStore.updateData();
    };

    return (
        <Dialog
            open={$membersDataStore.onEditRoleDialog}
            onClose={() => {
                $membersDataStore.setOnEditRoleDialog(false);
            }}
            title={"Редактирование прав"}
            subTitle={memberData.name}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                        justifyContent={"space-between"}
                    >
                        <Grid item xs={11}>
                            <AutocompleteMultiple
                                noOptionsText={"Роль не найдена"}
                                id="role"
                                value={selectedRoles}
                                options={roleList}
                                getOptionLabel={(option: any) => option.name}
                                label="Роль"
                                isOptionEqualToValue={(option: any, value: any) => option.guid === value.guid}
                                onChange={(e, v) => {
                                    handleRolesSelect(v as Role[]);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <CustomTooltip tooltip={""} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ButtonSquare
                        variant="contained"
                        color="primary"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "15px auto",
                        }}
                        onClick={handleSubmit}
                    >
                        Сохранить
                    </ButtonSquare>
                </Grid>
            </Grid>
        </Dialog>
    );
});
export default EditRoleDialog;
