import { Point as GeoJsonPoint } from 'geojson';
import axios from '../axios';
import Notification from '../components/ui/Notification';
import {
    HouseInfo,
    HouseList,
} from '../types/houseListInfo';
import { Organisations } from '../types/organisation';
import { DEFAULT_COORDINATES } from '../utils/const';

export async function getHouseListInfo(search?: string) {
    let data = {
        page: 1,
        pageSize: 100,
        orderBy: "address",
        direction: "asc",
        search: search,
    };
    let config = {
        method: "post",
        url: "/HouseService/Houses_list",
        data: data,
    };
    try {
        const response = await axios.request(config);
        let result: HouseList = response.data.data;
        const items: HouseInfo[] = result.items.length
            ? result.items
                  .sort((a, b) => a?.address.localeCompare(b?.address))
                  .map((item: any) => {
                      return {
                          ...item,
                          point:
                              item.point !== ""
                                  ? (JSON.parse(item.point) as GeoJsonPoint)
                                  : (DEFAULT_COORDINATES as GeoJsonPoint),
                      };
                  })
            : [];

        result = { ...result, items: items };

        if (!result) {
            Notification({
                title: 'Не удалось получить список домов',
                type: 'error',
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить список домов',
            type: 'error',
        });
    }
}

export async function getUKList() {
    let data = {
        is_uk: 1,
        pageSize: 99999,
        orderBy: 'name',
        direction: 'asc',
    };
    let config = {
        method: "post",
        url: "/UserService/Organisations_list",
        data: data,
    };
    try {
        const response = await axios.request(config);

        let result: Organisations = response.data.data.items.length
            ? response.data.data.items
            : [];
        if (!result) {
            Notification({
                title: 'Не удалось получить список управляющих компаний',
                type: 'error',
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить список управляющих компаний',
            type: 'error',
        });
    }
}

export async function getOrganisationList() {
    let data = {
        is_uk: 0,
        pageSize: 99999,
        orderBy: 'name',
        direction: 'asc',
    };
    let config = {
        method: "post",
        url: "/UserService/Organisations_list",
        data: data,
    };
    try {
        const response = await axios.request(config);

        let result: Organisations = response.data.data.items.length
            ? response.data.data.items
            : [];
        if (!result) {
            Notification({
                title: 'Не удалось получить список организаций',
                type: 'error',
            });
            return;
        }
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось получить список организаций',
            type: 'error',
        });
    }
}
