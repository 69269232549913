import { Grid, TextField, Typography } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { saveAttachedUk } from "../../../api-actions/MembersApi";
import { $membersDataStore } from "../../../store/MembersStore";
import { $ukStore } from "../../../store/UkStore";
import { Organisation } from "../../../types/organisation";
import Notification from "../../ui/Notification";
import CustomTooltip from "../../ui/Tooltip";
import AutocompleteMultiple from "../../ui/AutocompleteMultiple";
import { Item } from "../../../types/ui/autocomplete";
import Dialog from "../../ui/dialog/Dialog";

const AttachUkDialog = observer(() => {
    const { memberData } = $membersDataStore;

    const ukList = $ukStore.data?.filter((item) => {
        return (
            item.guid !== memberData.organisation.guid &&
            !memberData.linkedUk?.items.some((el) => el.guid === item.guid)
        );
    });

    const [strUk, setStrUk] = useState("");
    const [selectedUk, setSelectedUk] = useState<[] | Item[]>(memberData.linkedUk ? memberData.linkedUk.items : []);

    const handleUKSelect = useCallback((ukList: Organisation[]) => {
        const strOfGuid = ukList.map((item) => item.guid).join(",");
        setStrUk(strOfGuid);
        setSelectedUk(ukList);
    }, []);

    const handleSubmit = async () => {
        $membersDataStore.setLoading(true);

        const ukInfo = {
            user: memberData.guid,
            uk: strUk,
        };
        await saveAttachedUk(ukInfo).then((resp) => {
            $membersDataStore.setOnAttachUkDialog(false);
            Notification({
                title: "Сохранено",
                type: "success",
                duration: 30000,
            });
        });
        $membersDataStore.setLoading(false);
        $membersDataStore.updateData();
    };

    const subTitle = (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <Typography variant={"subtitle2"}>{"ФИО: " + memberData.name}</Typography>
            </Grid>
            <Grid item>
                <Typography variant={"subtitle2"}>{"Основная УК: " + memberData.organisation.name}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <Dialog
            open={$membersDataStore.onAttachUkDialog}
            onClose={() => {
                $membersDataStore.setOnAttachUkDialog(false);
            }}
            title="Присоединить УК"
            subTitle={subTitle}
        >
            <Grid container spacing={2}>
                <Grid container direction={"row"} alignItems={"center"} spacing={1} justifyContent={"space-between"}>
                    <Grid item xs={11}>
                        <AutocompleteMultiple
                            noOptionsText={"УК не найдены"}
                            id="uk"
                            value={selectedUk}
                            options={ukList ?? []}
                            getOptionLabel={(option: any) => option.name}
                            label="УК"
                            isOptionEqualToValue={(option: any, value: any) => option.guid === value.guid}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Выберите УК"
                                    label="УК"
                                    required
                                />
                            )}
                            onChange={(e, v) => {
                                handleUKSelect(v as Organisation[]);
                            }}
                            sx={{
                                "& .MuiFormHelperText-root": {
                                    margin: "3px 0 0",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <CustomTooltip tooltip={""} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ButtonSquare
                        variant="contained"
                        color="primary"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "15px auto",
                        }}
                        onClick={handleSubmit}
                    >
                        Сохранить
                    </ButtonSquare>
                </Grid>
            </Grid>
        </Dialog>
    );
});
export default AttachUkDialog;
