import { Grid, TextField } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { checkImei, newDeviceCreate } from "../../../api-actions/DevicesApi";
import { $deviceTableDataStore } from "../../../store/DeviceTableStore";
import { $loginUserStore } from "../../../store/ResponseData";
import { Device } from "../../../types/device";
import Notification from "../../ui/Notification";
import CustomTooltip from "../../ui/Tooltip";
import Autocomplete from "../../ui/Autocomplete";
import { Item } from "../../../types/ui/autocomplete";
import { green } from "@mui/material/colors";
import Dialog from "../../ui/dialog/Dialog";

const AddDevice = observer(() => {
    const isEdit = $deviceTableDataStore.editDevice;
    const [form, setForm] = useState<Device>($deviceTableDataStore.editDeviceData);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [checkingImei, setCheckingImei] = useState(false);
    const [modelSelected, setModelSelected] = useState<Item>({
        id: "",
        label: $deviceTableDataStore.editDeviceData.model,
    });

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });

            if (value !== "" && errors.hasOwnProperty(name)) {
                setErrors({
                    ...errors,
                    [name]: "",
                });
            }
        },
        [form, errors]
    );

    const checkImeiExists = async () => {
        await checkImei(form.imei).then((response) => {
            if (response === "success") {
                setErrors({
                    ...errors,
                    imei: "Данный идентификатор уже существует",
                });
            }
        });
    };

    useEffect(() => {
        if ($deviceTableDataStore.editDeviceData.imei !== form.imei && form.imei.length > 0) {
            setCheckingImei(true);
            checkImeiExists();
            setCheckingImei(false);
        }
    }, [form.imei]);

    const modelList: Item[] = [
        { id: "1", label: "Wan Way S20 (2G)" },
        { id: "2", label: "Wan Way S20 (4G)" },
        { id: "3", label: "Мовирег АТ-5800" },
    ];
    // удаление пробелов из телефонов, так как раньше маска допускала пробелы и с пробелами сохранялось в базе.
    useEffect(() => {
        const phoneFix = form.phone.replace(/\s+/g, "");
        if (phoneFix !== form.phone) {
            setForm((prevForm) => ({
                ...prevForm,
                phone: phoneFix,
            }));
        }
    }, [form.phone]);

    const handleSubmit = async () => {
        let newErrors: Record<string, string> = {};
        if (form.name === "") {
            newErrors.name = 'Поле "Название устройства" обязательно для заполнения';
        }
        if (form.imei === "") {
            newErrors.imei = 'Поле "Идентификатор устройства (IMEI)" обязательно для заполнения';
        }
        if (form.model === "") {
            newErrors.model = 'Поле "Модель" обязательно для заполнения';
        }
        const phoneRegex = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;

        if (form.phone && !phoneRegex.test(form.phone)) {
            newErrors.phone = 'Поле "Телефон" заполнено некорректно';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length <= 0) {
            $deviceTableDataStore.setLoadingDeviceTable(true);
            await newDeviceCreate(form).then((resp) => {
                if (typeof resp === "string") {
                    setErrors({
                        ...errors,
                        imei: resp,
                    });
                    return;
                }

                $deviceTableDataStore.updateData();
                $deviceTableDataStore.setAddNewDevice(false);
                $deviceTableDataStore.setEditDevice(false);
                if (isEdit) {
                    Notification({
                        title: "Устройство обновлено",
                        type: "success",
                        duration: 30000,
                    });
                } else {
                    Notification({
                        title: "Устройство зарегистрировано",
                        message: "Для закрепления за устройством сотрудника воспользуйтесь функционалом «Закрепление»",
                        type: "success",
                        duration: 30000,
                    });
                }
            });
            $deviceTableDataStore.setLoadingDeviceTable(false);
        }
    };

    const PhoneMaskedTextField = useCallback((props: any) => {
        return (
            <InputMask mask="+7(999)999-99-99" maskChar="" {...props} style={{ width: "100%" }}>
                {(inputProps: any) => (
                    <TextField
                        placeholder="№ телефона устройства"
                        label="№ телефона устройства"
                        name="phone"
                        id="phone"
                        {...inputProps}
                        disabled={props.disabled}
                        error={props.errors?.length > 0}
                        helperText={props.errors?.length > 0 ? props.errors : false}
                    />
                )}
            </InputMask>
        );
    }, []);

    return (
        <Dialog
            open={$deviceTableDataStore.addNewDevice || $deviceTableDataStore.editDevice}
            onClose={() => {
                $deviceTableDataStore.setAddNewDevice(false);
                $deviceTableDataStore.setEditDevice(false);
            }}
            title={isEdit ? "Редактирование устройства" : "Регистрация нового устройства"}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    container
                    direction={"row"}
                    alignItems={"start"}
                    spacing={1}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <TextField
                            placeholder="Название устройства"
                            label="Название устройства"
                            required
                            size="medium"
                            variant="outlined"
                            fullWidth
                            name="name"
                            id="name"
                            value={form.name}
                            onChange={(value) => setFormValue("name", value.target.value)}
                            error={errors.name?.length > 0}
                            helperText={errors.name?.length > 0 ? errors.name : false}
                        />
                    </Grid>
                    <Grid item xs={1} mt={'16px'}>
                        <CustomTooltip tooltip={""} />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={"row"}
                    alignItems={"start"}
                    spacing={1}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <TextField
                            disabled={!$loginUserStore.isAdmin() || checkingImei}
                            placeholder="Идентификатор устройства (IMEI)"
                            label="Идентификатор устройства (IMEI)"
                            required
                            size="medium"
                            variant="outlined"
                            fullWidth
                            name="imei"
                            id="imei"
                            value={form.imei}
                            onChange={(value) => setFormValue("imei", value.target.value)}
                            error={errors.imei?.length > 0}
                            helperText={errors.imei?.length > 0 ? errors.imei : false}
                        />
                    </Grid>
                    <Grid item xs={1} mt={'16px'}>
                        <CustomTooltip tooltip={""} />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={"row"}
                    alignItems={"start"}
                    spacing={1}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <Autocomplete
                            disabled={!$loginUserStore.isAdmin()}
                            noOptionsText={"Модель не найдена"}
                            id="model"
                            value={modelSelected}
                            options={modelList}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Модель"
                                    label="Модель"
                                    required
                                    error={errors.model?.length > 0}
                                    helperText={errors.model?.length > 0 ? errors.model : false}
                                    slotProps={{
                                        formHelperText: {
                                            sx: {
                                                color: green,
                                            },
                                        },
                                    }}
                                />
                            )}
                            onChange={(e, value) => {
                                setModelSelected(value ?? { id: "", label: "" });
                                setFormValue("model", value?.label);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} mt={'16px'}>
                        <CustomTooltip tooltip={""} />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <PhoneMaskedTextField
                            disabled={!$loginUserStore.isAdmin()}
                            variant="outlined"
                            onChange={(evt: any) => setFormValue("phone", evt.target.value)}
                            value={form.phone}
                            errors={errors.phone}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <TextField
                            disabled={!$loginUserStore.isAdmin()}
                            placeholder="Описание"
                            label="Описание"
                            size="medium"
                            variant="outlined"
                            fullWidth
                            name="description"
                            id="description"
                            value={form.description}
                            onChange={(evt: any) => setFormValue("description", evt.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ButtonSquare
                        disabled={Object.values(errors).some((value) => value !== "")}
                        variant="contained"
                        color="primary"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "15px auto",
                        }}
                        onClick={handleSubmit}
                    >
                        {isEdit ? "Обновить" : "Зарегистрировать"}
                    </ButtonSquare>
                </Grid>
            </Grid>
        </Dialog>
    );
});
export default AddDevice;
