import { Grid } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { CustomCircularProgress } from "../CustomCircularProgress";
import Dialog from "../../ui/dialog/Dialog";

type ConfirmDialogProps = {
    open: boolean;
    title: string;
    submitText: string;
    onClose: () => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    loading: boolean;
    submitIsDisable?: boolean;
};

const ConfirmDialog = observer((props: ConfirmDialogProps) => {
    const open = props.open ?? false;
    const loading = props.loading ?? false;
    const submitIsDisable = props.submitIsDisable ?? false;
    const title = props.title ?? "";
    const submitText = props.submitText ?? "";
    const onClose = !!props.onClose ? props.onClose : () => {};
    const onSubmit = !!props.onSubmit ? props.onSubmit : () => {};
    const children = !!props.children ? props.children : <></>;

    return (
        <Dialog open={open} onClose={onClose} title={title}>
            {loading ? (
                <div
                    style={{
                        padding: "55px 0",
                    }}
                >
                    <CustomCircularProgress />
                </div>
            ) : (
                <Grid container spacing={2}>
                    <Grid item>{children}</Grid>
                    <Grid item container flexDirection={"row"} justifyContent={"center"}>
                        <Grid item>
                            <ButtonSquare
                                disabled={submitIsDisable}
                                variant="contained"
                                color="primary"
                                style={{
                                    margin: "10px 15px",
                                }}
                                onClick={onSubmit}
                            >
                                {submitText}
                            </ButtonSquare>
                        </Grid>
                        <Grid item>
                            <ButtonSquare
                                variant="outlined"
                                style={{
                                    margin: "10px 15px",
                                }}
                                onClick={onClose}
                            >
                                Отмена
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Dialog>
    );
});

export default ConfirmDialog;
