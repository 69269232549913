import { Grid, MenuItem, Paper, TableContainer } from "@mui/material";
import { SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { StyledCard } from "../../../App";
import AttachUkDialog from "./AttachUkDialog";
import EditRoleDialog from "./EditRoleDialog";
import { $membersDataStore } from "../../../store/MembersStore";
import { $loginUserStore } from "../../../store/ResponseData";
import MainContentWrap from "../../ui/wrappers/MainContentWrap";
import Table from "../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { Member } from "../../../types/member";

const Members = observer(() => {
    const {
        membersPage,
        membersRowsPerPage,
        membersloadingTable,
        membersSort,
        membersSearch,
        membersData,
        organisation,
    } = $membersDataStore;

    const columns: MRT_ColumnDef<Member>[] = [
        {
            accessorKey: "name",
            header: "ФИО сотрудника",
            size: 300,
        },
        {
            accessorKey: "organisation.name",
            header: "Организация",
            size: 300,
        },
        {
            id: "roles",
            accessorFn: (row) => row.roles?.items.map((role) => role.name).join(", "),
            header: "Роль",
            size: 100,
            enableHiding: $loginUserStore.isAdmin(),
        },
    ];

    useEffect(() => {
        $membersDataStore.getRoleList();
    }, []);

    useEffect(() => {
        $membersDataStore.getData(
            membersPage + 1,
            membersSort.by,
            membersSort.order,
            membersRowsPerPage,
            membersSearch,
            organisation
        );
    }, [membersSearch, membersPage, membersRowsPerPage, membersSort, organisation]);

    const changeTableSort = useCallback(
        (key: string) => {
            $membersDataStore.setSort({
                by: key,
                order: membersSort.by !== key ? "asc" : membersSort.order === "asc" ? "desc" : "asc",
            });
        },
        [membersSort]
    );

    const handleChangePage = (newPage: number) => {
        $membersDataStore.setPage(newPage);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $membersDataStore.setRowsPerPage(v);
        $membersDataStore.setPage(0);
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em",
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Сотрудники</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SearchField
                            value={membersSearch}
                            callback={(value) => $membersDataStore.setSearch(value)}
                            callbackTimeout={700}
                            style={{
                                width: 300,
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledCard>
                    <TableContainer component={Paper}>
                        <Table
                            data={membersData?.items}
                            columns={columns}
                            loading={membersloadingTable}
                            sortProps={membersSort}
                            pageIndex={membersPage}
                            pageSize={membersRowsPerPage}
                            changeTableSort={changeTableSort}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowCount={membersData?.pagination.total! ?? 0}
                            enableRowActions={$loginUserStore.isAdmin()}
                            renderRowActionMenuItems={({ closeMenu, row }) => [
                                <MenuItem
                                    onClick={() => {
                                        $membersDataStore.setMemberData(row.original);
                                        $membersDataStore.setOnEditRoleDialog(true);
                                        closeMenu();
                                    }}
                                >
                                    Редактировать права
                                </MenuItem>,
                                row.original.roles?.items.find((el: any) => el.name === "Руководитель региона") && (
                                    <MenuItem
                                        onClick={() => {
                                            $membersDataStore.setMemberData(row.original);
                                            $membersDataStore.setOnAttachUkDialog(true);
                                            closeMenu();
                                        }}
                                    >
                                        Прикрепить УК
                                    </MenuItem>
                                ),
                            ]}
                        />
                    </TableContainer>
                    {$membersDataStore.onEditRoleDialog && <EditRoleDialog />}
                    {$membersDataStore.onAttachUkDialog && <AttachUkDialog />}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});
export default Members;
