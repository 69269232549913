import {AccountBox, Visibility, VisibilityOff } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import { Button, Grid, InputAdornment, IconButton  } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { observer } from "mobx-react";
import React, { FormEvent, useCallback, useMemo, useState } from "react";
import logo from "../../icons/logo.png";
import map from "../../icons/map.svg";
import { $loginUserStore } from "../../../store/ResponseData";
import { UserData } from "../../../types/user.data";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../../../api-actions/LoginApi";

const StyledButton = styled(Button)({
    background: "#20a0ff",
    borderRadius: "40px",
    fontFamily: "ProximaNova",
    height: "45px",
    width: "100px",
    color: "#fff",
    marginRight: "73%",
    marginTop: "20px",
    textTransform: "initial",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.25px",
    fontWeight: "600",
});

const Login = observer((): JSX.Element => {
    const [form, setForm] = useState<UserData>({ login: "", password: "" });
    const [validationErrors, setValidationErrors] = useState<UserData>({
        login: "",
        password: "",
    });
    const [showPassword, setShowPassword] = React.useState(false);

    const emptyValidationErrors = useMemo(() => {
        return { login: "", password: "" };
    }, []);

    const normalizeData = () => {
        const login = form.login.trim();
        const password = form.password.trim();
        setForm({ login, password });
        return { login, password };
    };

    const setFormValue = useCallback(
        (name: string, value: string) => {
            value = value.trimStart();
            setForm({ ...form, [name]: value });
            setValidationErrors({ ...validationErrors, [name]: "" });
        },
        [form, validationErrors]
    );

    const onSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
            e.preventDefault();
            setValidationErrors(emptyValidationErrors);
            const data = normalizeData();
            const response = await loginUser(data);
            if (!response) return;
            $loginUserStore.setData(response.data);
        },
        [emptyValidationErrors, form]
    );

    const inputKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") {
                normalizeData();
                onSubmit(e);
            }
        },
        [onSubmit]
    );

    const isValid = useMemo(() => {
        return form.login.match(/[^\s]/gm) && form.password.match(/[^\s]/gm);
    }, [form.login, form.password]);

    return (
        <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: {
                    xs: "100vh",
                    md: "calc(100vh - 30px)",
                },
            }}
        >
            <Grid item xs={12} md={3}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                        maxWidth: {
                            xs: "75vw",
                            md: 300,
                        },
                        minWidth: {
                            xs: "75vw",
                            md: 300,
                        },
                        margin: "0 auto",
                    }}
                >
                    <img src={logo} alt="ОКОЛО" width={100} />
                    <h2>Добро пожаловать!</h2>
                    <form onSubmit={onSubmit} noValidate>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    size="medium"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    placeholder="Логин"
                                    name="login"
                                    id="login"
                                    onKeyUp={inputKeyPress}
                                    onBlur={normalizeData}
                                    value={form.login}
                                    onChange={(value) => setFormValue("login", value.target.value)}
                                    error={validationErrors.login.length > 0}
                                    helperText={validationErrors.login.length > 0 ? validationErrors.login : false}
                                    InputProps={{
                                        startAdornment: (
                                            <>
                                                <InputAdornment position="start">
                                                    <AccountBox />
                                                </InputAdornment>
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    sx={{
                                                        border: "1px solid #5ac8fa",
                                                    }}
                                                />
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="medium"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Пароль"
                                    name="password"
                                    id="password"
                                    onKeyUp={inputKeyPress}
                                    onBlur={normalizeData}
                                    value={form.password}
                                    onChange={(value) => setFormValue("password", value.target.value)}
                                    error={validationErrors.password.length > 0}
                                    helperText={
                                        validationErrors.password.length > 0 ? validationErrors.password : false
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <>
                                                <InputAdornment position="start">
                                                    <LockIcon />
                                                </InputAdornment>
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    sx={{
                                                        border: "1px solid #5ac8fa",
                                                    }}
                                                />
                                            </>
                                        ),
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={()=>setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledButton
                                    type="submit"
                                    variant="contained"
                                    disabled={!isValid}
                                    style={{ width: "100%" }}
                                >
                                    Войти
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            <Grid
                item
                md={9}
                sx={{
                    background: "#fff",
                    display: {
                        xs: "none",
                        md: "block",
                    },
                }}
            >
                <img
                    src={map}
                    alt=""
                    style={{
                        width: "70vw",
                        borderLeft: "1px solid #5ac8fa",
                        height: "100%",
                        maxHeight: "100vh",
                        minHeight: "100vh",
                        margin: -15,
                    }}
                />
            </Grid>
        </Grid>
    );
});

export default Login;
