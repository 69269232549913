import { Grid, TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useMemo, useState, useEffect } from "react";
import { addContractUserList } from "../../../../../api-actions/ContractsApi";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { contractMember, MemberItem } from "../../../../../types/contractMember";
import { getOrganisationName, getUKName } from "../../../../../utils/utils";
import AutocompleteMultiple from "../../../../ui/AutocompleteMultiple";
import dayjs from "dayjs";
import DatePicker from "../../../../ui/DatePicker";
import Dialog from "../../../../ui/dialog/Dialog";

const AddUser = observer(() => {
    const [error, setError] = useState("");
    const [selectedUsers, setSelectedUsers] = useState<[] | MemberItem[]>([]);
    const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

    const {
        contractMembersPage,
        contractMembersRowsPerPage,
        contractMembersSort,
        contractMembersSearch,
        contractDetail,
    } = $contractsDataStore;

    const [form, setForm] = useState<contractMember>({
        guid: "",
        contract: contractDetail?.guid!,
        user: "",
        status: "WORK",
        name: "",
        start: "",
        end: "",
    });

    const users = useMemo(() => {
        return contractDetail?.available_users?.items.map((item) => {
            return {
                id: item.id,
                label: item.name,
            };
        });
    }, [contractDetail?.available_users?.items]);

    useEffect(() => {
        if (selectedUsers) {
            setIsSelectedAll(users?.length === selectedUsers.length);
        }
    }, [selectedUsers, users]);

    const handleSelectedUsers = () => {
        if (isSelectedAll) {
            setForm({ ...form, user: "" });
            setSelectedUsers([]);
        } else {
            if (users) {
                const strOfGuid = users.map((item) => item.id).join(",");
                setForm({ ...form, user: strOfGuid });
                setSelectedUsers(users);
            }
        }
    };

    const handleUsersSelect = useCallback((users: MemberItem[]) => {
        console.log("users: ", users);
        const strOfGuid = users.map((item) => item.id).join(",");
        setForm((prevForm) => ({
            ...prevForm,
            user: strOfGuid,
        }));
        setSelectedUsers(users);
    }, []);

    const handleSubmit = async () => {
        if (!form.start) {
            setError("Дата начала обязательна!");
            return;
        }

        $contractsDataStore.setLoading(true);
        await addContractUserList(form).then((contract) => {
            $contractsDataStore.getMembersList(
                String(contractDetail?.guid),
                contractMembersPage + 1,
                contractMembersSort.by,
                contractMembersSort.order,
                contractMembersRowsPerPage,
                contractMembersSearch
            );
            $contractsDataStore.getDetail(String(contractDetail?.guid));
            $contractsDataStore.setAddNewUser(false);
        });
        $contractsDataStore.setMembersLoading(false);
    };

    const normalizeDate = (dateString: string) => {
        const normDate = dayjs(dateString).format("YYYY-MM-DD");
        return new Date(normDate).getTime();
    };

    useEffect(() => {
        let textError = "";

        if (contractDetail?.start && form.start) {
            const contractDateStart = normalizeDate(contractDetail?.start);
            const currentdate = normalizeDate(form.start);

            if (contractDateStart > currentdate) {
                textError =
                    "Дата не может быть раньше даты начала действия договора (" +
                    dayjs(contractDetail?.start).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (contractDetail?.end && form.start) {
            const contractDateEnd = normalizeDate(contractDetail?.end);
            const currentdate = normalizeDate(form.start);

            if (contractDateEnd < currentdate) {
                textError =
                    "Дата не может быть позже даты окончания действия договора (" +
                    dayjs(contractDetail?.end).format("DD.MM.YYYY") +
                    ")";
            }
        }

        setError(textError);
    }, [form.start]);

    const contractName = $contractsDataStore.getName(contractDetail);
    const contractFrom = contractDetail?.from ? getUKName(contractDetail?.from) : " ";
    const contractTo = contractDetail?.to ? getOrganisationName(contractDetail?.to) : " ";

    return (
        <Dialog
            open={$contractsDataStore.addNewUser}
            onClose={() => $contractsDataStore.setAddNewUser(false)}
            title={"Добавить сотрудника к договору"}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Управляющая компания"
                        InputProps={{
                            readOnly: true,
                        }}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={contractFrom}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Организация"
                        InputProps={{
                            readOnly: true,
                        }}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={contractTo}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Номер договора"
                        InputProps={{
                            readOnly: true,
                        }}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={contractName}
                    />
                </Grid>
                <Grid item container xs={12} justifyContent={"flex-end"}>
                    <DatePicker
                        value={form.start ? dayjs(form.start) : null}
                        label={"Дата начала"}
                        slotProps={{
                            textField: {
                                clearable: true,
                                error: !!error,
                                helperText: error,
                                sx: {
                                    width: "208px",
                                },
                            },
                        }}
                        onChange={(value) => {
                            const newDate = value && dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : "";
                            setForm({ ...form, start: newDate });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteMultiple
                        noOptionsText={"Сотрудник не найден"}
                        id="user"
                        value={selectedUsers}
                        options={users !== undefined ? users : []}
                        getOptionLabel={(option: any) => option.label}
                        label="Сотрудник"
                        onChange={(e, v) => {
                            handleUsersSelect(v as MemberItem[]);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={isSelectedAll} onChange={handleSelectedUsers} />}
                            label={isSelectedAll ? "Снять все" : "Выбрать все"}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <ButtonSquare
                        disabled={!!error}
                        variant="contained"
                        color="primary"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "15px auto",
                        }}
                        onClick={handleSubmit}
                    >
                        Добавить
                    </ButtonSquare>
                </Grid>
            </Grid>
        </Dialog>
    );
});
export default AddUser;
