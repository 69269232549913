import { createFilterOptions, Grid, TextField } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { useCallback, useMemo, useState } from "react";
import { newContractCreate } from "../../../api-actions/ContractsApi";
import { $contractsDataStore } from "../../../store/ContractsStore";
import { $organisationsStore } from "../../../store/OrganisationsStore";
import { $ukStore } from "../../../store/UkStore";
import CustomTooltip from "../../ui/Tooltip";
import Autocomplete from "../../ui/Autocomplete";
import Dialog from "../../ui/dialog/Dialog";
import { Item } from "../../../types/ui/autocomplete";
import DatePicker from "../../ui/DatePicker";

const AddContract = observer(() => {
    const { contractsPage, contractsRowsPerPage, contractsSort, contractsSearch, editContractData } =
        $contractsDataStore;

    const [form, setForm] = useState(editContractData);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [ukSelected, setUkSelected] = useState<Item>({
        id: "",
        name: "",
    });

    const [organisationSelected, setOrganisationSelected] = useState<Item>({
        id: "",
        name: "",
    });

    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

    const { data: uks } = $ukStore;
    const ukList = useMemo(() => {
        return uks?.map((item) => {
            return { id: item.guid, name: item.name };
        });
    }, [uks]);

    const { data: organisations } = $organisationsStore;
    const organisationList = useMemo(() => {
        return organisations?.map((item) => {
            return { id: item.guid, name: item.name };
        });
    }, [organisations]);

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
            if (value !== "" && errors.hasOwnProperty(name)) {
                setErrors({
                    ...errors,
                    [name]: "",
                });
            }
        },
        [form, errors]
    );

    const handleChangeUk = (value: Item) => {
        const fromGuid = uks?.find((item) => item.name === value?.name)?.guid ?? "";
        if (fromGuid) {
            setUkSelected(value);
        } else {
            setUkSelected({
                id: "",
                name: "",
            });
        }

        setForm({
            ...form,
            from: fromGuid,
        });

        if (fromGuid !== "") {
            setErrors({
                ...errors,
                from: "",
            });
        }
    };

    const handleChangeOrganisation = (value: Item) => {
        const toGuid = organisations?.find((item) => item.name === value?.name)?.guid ?? "";
        if (toGuid) {
            setOrganisationSelected(value);
        } else {
            setOrganisationSelected({
                id: "",
                name: "",
            });
        }

        setForm({
            ...form,
            to: toGuid,
        });

        if (toGuid !== "") {
            setErrors({
                ...errors,
                to: "",
            });
        }
    };

    const handleSubmit = async () => {
        let newErrors: Record<string, string> = {};
        if (form.from === "") {
            newErrors.from = 'Поле "Управляющая компания" обязательно для заполнения';
        }
        if (form.to === "") {
            newErrors.to = 'Поле "Организация" обязательно для заполнения';
        }
        if (form.number === "") {
            newErrors.number = 'Поле "№ договора" обязательно для заполнения';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length <= 0) {
            $contractsDataStore.setLoading(true);
            setDisabledSubmitButton(true);

            await newContractCreate(form).then((contract) => {
                if (contract) {
                    $contractsDataStore.getData(
                        contractsPage + 1,
                        contractsSort.by,
                        contractsSort.order,
                        contractsRowsPerPage,
                        contractsSearch
                    );
                    $contractsDataStore.setOpenEditContract(false);
                }
            });
            $contractsDataStore.setLoading(false);
            setDisabledSubmitButton(false);
        }
    };

    const filterOptions = (options: Item[], inputValue: string) => {
        const normalize = (str: string) => str.replace(/[\s'"«»‹›„“]+/g, "").toLowerCase();
        const normalizedInput = normalize(inputValue);

        const filteredOptions = options.filter((option) => {
            const normalizedName = normalize(option.name ?? "");
            return normalizedName.includes(normalizedInput);
        });

        return filteredOptions;
    };

    return (
        <Dialog
            open={$contractsDataStore.openEditContract}
            onClose={() => $contractsDataStore.setOpenEditContract(false)}
            title={form.guid ? "Редактирование договора" : "Создание нового договора"}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    container
                    direction={"row"}
                    alignItems={"start"}
                    spacing={1}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <Autocomplete
                            noOptionsText={"УК не найдена"}
                            id="from"
                            value={ukSelected}
                            options={ukList !== undefined ? ukList : []}
                            filterOptions={(options, state) =>
                                filterOptions(options as Item[], state.inputValue as string)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Управляющая компания"
                                    label="Управляющая компания"
                                    required
                                    error={errors.from?.length > 0}
                                    helperText={errors.from?.length > 0 ? errors.from : false}
                                />
                            )}
                            onChange={(e, value) => {
                                handleChangeUk(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} mt={'16px'}>
                        <CustomTooltip tooltip={""} />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={"row"}
                    alignItems={"start"}
                    spacing={1}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <Autocomplete
                            noOptionsText={"Организация не найдена"}
                            id="to"
                            value={organisationSelected}
                            options={organisationList !== undefined ? organisationList : []}
                            filterOptions={createFilterOptions({
                                limit: 10,
                            })}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Организация"
                                    label="Организация"
                                    required
                                    error={errors.to?.length > 0}
                                    helperText={errors.to?.length > 0 ? errors.to : false}
                                />
                            )}
                            onChange={(e, value) => {
                                handleChangeOrganisation(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} mt={'16px'}>
                        <CustomTooltip tooltip={""} />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={"row"}
                    alignItems={"start"}
                    spacing={1}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <TextField
                                    placeholder="№ договора"
                                    label="№ договора"
                                    sx={{ width: "208px" }}
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    id="number"
                                    name="number"
                                    required
                                    value={form.number}
                                    onChange={(value) => setFormValue("number", value.target.value)}
                                    error={errors.number?.length > 0}
                                    helperText={errors.number?.length > 0 ? errors.number : false}
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker
                                    value={form.date ? dayjs(form.date) : null}
                                    label="Дата договора"
                                    slotProps={{
                                        textField: {
                                            clearable: true,
                                            sx: { width: "208px" },
                                        },
                                    }}
                                    onChange={(value) =>
                                        setFormValue("date", value ? dayjs(value).format("YYYY-MM-DD") : "")
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} mt={'16px'}>
                        <CustomTooltip tooltip={""} />
                    </Grid>
                </Grid>
                <Grid item container direction={"row"}>
                    <Grid
                        item
                        container
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                        justifyContent={"space-between"}
                    >
                        <Grid item xs={11}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item>
                                    <DatePicker
                                        value={form.start ? dayjs(form.start) : null}
                                        label="Начало действия"
                                        slotProps={{
                                            textField: {
                                                clearable: true,
                                                sx: { width: "208px" },
                                            },
                                        }}
                                        onChange={(value) => {
                                            setFormValue("start", value ? dayjs(value).format("YYYY-MM-DD") : "");
                                        }}
                                    />
                                    <CustomTooltip tooltip={""} style={{ margin: "19px" }} />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        value={form.end ? dayjs(form.end) : null}
                                        label="Окончание действия"
                                        slotProps={{
                                            textField: {
                                                clearable: true,
                                                sx: { width: "208px" },
                                            },
                                        }}
                                        onChange={(value) =>
                                            setFormValue("end", value ? dayjs(value).format("YYYY-MM-DD") : "")
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <CustomTooltip tooltip={""} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                        justifyContent={"space-between"}
                    >
                        <Grid item xs={11}>
                            <TextField
                                placeholder="Описание"
                                label="Описание"
                                size="medium"
                                variant="outlined"
                                fullWidth
                                name="description"
                                id="description"
                                value={form.description}
                                onChange={(value) => setFormValue("description", value.target.value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <CustomTooltip tooltip={""} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ButtonSquare
                        disabled={Object.values(errors).some((value) => value !== "") || disabledSubmitButton}
                        variant="contained"
                        color="primary"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "15px auto",
                        }}
                        onClick={handleSubmit}
                    >
                        {form.guid ? "Изменить" : "Создать"}
                    </ButtonSquare>
                </Grid>
            </Grid>
        </Dialog>
    );
});
export default AddContract;
