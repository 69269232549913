import { Grid, TextField } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { useCallback, useState, useEffect } from "react";
import { getContractNorm, editContractNorm } from "../../../../../api-actions/ContractsApi";
import { Normative } from "../../../../../types/normativeData";
import dayjs from "dayjs";
import DatePicker from "../../../../ui/DatePicker";
import Dialog from "../../../../ui/dialog/Dialog";

const EditNormativeData = observer(() => {
    const { normPage, normRowsPerPage, normSort, normSearch, contractDetail, currentNormGuid, normList } =
        $contractsDataStore;
    const [form, setForm] = useState<Normative>({
        guid: currentNormGuid,
        contract: contractDetail?.guid!,
        start: "",
        end: "",
        plan_workers: 0,
        hours_worker: 0,
    });
    const [errors, setErrors] = useState({
        start: "",
        end: "",
    });

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
        },
        [form]
    );

    useEffect(() => {
        // Проверка: если start больше end
        if (form.start && form.end) {
            // Проверка на ошибки
            let startTextError = "";
            let endTextError = "";
            const formStart = new Date(form.start).getTime();
            const formEnd = new Date(form.end).getTime();

            if (formStart > formEnd) {
                startTextError = "Дата начала не может быть позже даты окончания";
                endTextError = "Дата окончания не может быть раньше даты начала";
            }

            // Проверка на пересечения с другими элементами
            if (normList) {
                normList.items.forEach((item) => {
                    // исключаем сравнение элемнта с самим собой в списке
                    if (item.guid !== form.guid) {
                        const itemStart = new Date(item.start).getTime();
                        const itemEnd = new Date(item.end).getTime();

                        // Проверяем, входит ли start или end в диапазон другого периода
                        if (formStart >= itemStart && formStart <= itemEnd) {
                            startTextError = "Дата начала пересекается с другими периодами";
                        }

                        if (formEnd >= itemStart && formEnd <= itemEnd) {
                            endTextError = "Дата окончания пересекается с другими периодами";
                        }
                        // проверяем, пересекает ли введенный период полностью другой период
                        if (itemStart >= formStart && itemEnd <= formEnd) {
                            endTextError = "Введенный период пересекается с другим периодом";
                        }
                    }
                });
            }
            const fieldsErrors = { start: startTextError, end: endTextError };
            setErrors(fieldsErrors);
        }
    }, [form.start, form.end]);

    const handleSubmit = async () => {
        let startTextError = "";
        let endTextError = "";

        // Проверка: если даты отсутствуют
        if (!form.start) {
            startTextError = "Поле обязательное для заполнения";
        }
        if (!form.end) {
            endTextError = "Поле обязательное для заполнения";
        }

        if (startTextError || endTextError) {
            setErrors({ start: startTextError, end: endTextError });
            return;
        }

        $contractsDataStore.setLoading(true);
        await editContractNorm(form).then((contract) => {
            $contractsDataStore.getNormList(
                String(contractDetail?.guid),
                normPage + 1,
                normSort.by,
                normSort.order,
                normRowsPerPage,
                normSearch
            );
            $contractsDataStore.getDetail(String(contractDetail?.guid));
            $contractsDataStore.setEditNorm(false);
        });
        $contractsDataStore.setNormLoading(false);
        clearForm();
    };

    const getNormData = async () => {
        if (form.guid) {
            await getContractNorm(form.guid).then((norm) => {
                if (norm) {
                    setForm({
                        ...form,
                        start: norm.start,
                        end: norm.end,
                        plan_workers: norm.plan_workers,
                        hours_worker: norm.hours_worker,
                    });
                }
            });
        }
    };

    useEffect(() => {
        getNormData();
    }, [form.guid, $contractsDataStore.editNorm]);

    const clearForm = () => {
        setForm({
            ...form,
            guid: "",
            contract: "",
            start: "",
            end: "",
            plan_workers: 0,
            hours_worker: 0,
        });
        $contractsDataStore.setCurrentNormGuid("");
    };

    const formatIntegerInput = (value: string) => {
        // Удаляем все символы, кроме цифр
        let sanitizedValue = value.replace(/[^0-9]/g, "");

        // Убираем ведущие нули
        sanitizedValue = sanitizedValue.replace(/^0+/, "");

        // Если строка пустая, возвращаем "0"
        return sanitizedValue.length > 0 ? sanitizedValue : "0";
    };

    return (
        <Dialog
            open={$contractsDataStore.editNorm}
            onClose={() => {
                $contractsDataStore.setEditNorm(false);
                clearForm();
            }}
            title={(form.guid ? "Изменить " : "Добавить ") + "норматив"}
        >
            <Grid container spacing={2}>
                <Grid item container spacing={1} justifyContent={"space-between"}>
                    <Grid item>
                        <DatePicker
                            value={form.start ? dayjs(form.start) : null}
                            label="Начало периода"
                            slotProps={{
                                textField: {
                                    clearable: true,
                                    error: !!errors.start,
                                    helperText: errors.start,
                                    sx: {
                                        width: "208px",
                                    },
                                },
                            }}
                            onChange={(value) => {
                                setErrors({ start: "", end: errors.end });
                                setFormValue("start", value ? dayjs(value).format("YYYY-MM-DD") : "");
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            value={form.end ? dayjs(form.end) : null}
                            label="Окончание периода"
                            slotProps={{
                                textField: {
                                    clearable: true,
                                    error: !!errors.end,
                                    helperText: errors.end,
                                    sx: {
                                        width: "208px",
                                    },
                                },
                            }}
                            onChange={(value) => {
                                setErrors({ start: errors.start, end: "" });
                                setFormValue("end", value ? dayjs(value).format("YYYY-MM-DD") : "");
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} justifyContent={"space-between"}>
                    <Grid item>
                        <TextField
                            label="норматив чел. план"
                            fullWidth
                            size="medium"
                            variant="outlined"
                            value={form.plan_workers}
                            sx={{ width: "208px" }}
                            onChange={(event) => {
                                const formattedValue = formatIntegerInput(event.target.value);
                                setFormValue("plan_workers", formattedValue);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="норматив чел/час план"
                            fullWidth
                            size="medium"
                            variant="outlined"
                            value={form.hours_worker}
                            sx={{ width: "208px" }}
                            onChange={(event) => {
                                const formattedValue = formatIntegerInput(event.target.value);
                                setFormValue("hours_worker", formattedValue);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ButtonSquare
                        disabled={!!errors.start || !!errors.end}
                        variant="contained"
                        color="primary"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "15px auto",
                        }}
                        onClick={handleSubmit}
                    >
                        {form.guid ? "Изменить" : "Добавить"}
                    </ButtonSquare>
                </Grid>
            </Grid>
        </Dialog>
    );
});
export default EditNormativeData;
