import { Box, LinearProgress, Typography, LinearProgressProps, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "../../axios";
import Notification from "../ui/Notification";

type propsType = {
    start: boolean;
    action: string;
    onSuccess: () => {};
    cacheKey: string;
};

export type ReportProgress = {
    message: string;
    progress: number;
};

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const ProgressBar = ({ start, action, onSuccess, cacheKey }: propsType) => {
    const [progressPercent, setProgressPercent] = useState(0);
    const [progressText, setProgressText] = useState("Запрос...");

    useEffect(() => {
        if (start) {
            progressLoad();
        } else {
            setProgressPercent(0);
        }
    }, [start]);

    const progressLoad = async () => {
        const data = {
            cache_key: cacheKey,
        };

        let config = {
            method: "post",
            url: action,
            data,
        };

        try {
            await axios.request(config).then((resp) => {
                if (resp.data.data) {
                    const data = resp.data.data;
                    setStatus(data);
                    setProgressText(data.message);
                    if (data != null && data.step < 2 && data.progress < 100) {
                        progressLoad();
                    } else {
                        progressFinish();
                    }
                } else if (resp.data.error) {
                    Notification({
                        title: resp.data.error.message,
                        type: "error",
                    });
                }
            });
        } catch (error) {
            Notification({
                title: "Ошибка получения прогресса по отчету",
                type: "error",
            });
        }
    };

    const setStatus = (obj: ReportProgress) => {
        if (obj != null) {
            setProgressText(obj.message);

            if (obj.progress > 0) {
                setProgressPercent(obj.progress);
            }
        }
    };

    const progressFinish = () => {
        setProgressPercent(100);
        onSuccess();
    };

    return (
        <Grid container>
            <Grid item xs={12} container justifyContent={"center"}>
                <Typography variant="subtitle1" component="div">
                    {progressText}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <LinearProgressWithLabel value={progressPercent} />
            </Grid>
        </Grid>
    );
};

export default ProgressBar;
