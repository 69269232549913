import CloseIcon from "@mui/icons-material/Close";
import { Dialog as DialogMUI, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react";

type DialogProps = {
    title: string;
    subTitle?: string | React.ReactNode;
    open: boolean;
    onClose?: () => void;
    children: React.ReactNode;
};

const Dialog = observer((props: DialogProps) => {
    const title = props.title ?? "";
    const subTitle = props.subTitle ?? "";
    const open = props.open ?? false;
    const onClose = !!props.onClose ? props.onClose : () => {};
    const children = !!props.children ? props.children : <></>;

    return (
        <DialogMUI open={open} keepMounted={true} maxWidth={false} scroll="body">
            {!!props.onClose ? (
                <IconButton
                    aria-label="close"
                    style={{
                        position: "absolute",
                        right: "-45px",
                        top: "-45px",
                        color: "white",
                    }}
                    onClick={onClose}
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
            ) : null}
            <Grid container direction="column" justifyContent="center" pb={2}>
                <Grid item px={2}>
                    <DialogTitle
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            variant="h1"
                            style={{
                                fontSize: "34px",
                                lineHeight: "36px",
                                letterSpacing: "0",
                                fontWeight: "600",
                                color: "#20a0ff",
                            }}
                            component="span"
                        >
                            {title}
                        </Typography>
                    </DialogTitle>
                    {subTitle ? (
                        <DialogTitle
                            style={{
                                marginBottom: "10px",
                                textAlign: "center",
                            }}
                        >
                            <Typography variant={"subtitle1"}>{subTitle}</Typography>
                        </DialogTitle>
                    ) : null}
                </Grid>
                <Grid item px={11}>
                    {children}
                </Grid>
            </Grid>
        </DialogMUI>
    );
});

export default Dialog;
