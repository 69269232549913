import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SearchField } from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    useCallback,
    useEffect,
} from 'react';
import {
    generatePath,
    useNavigate,
} from 'react-router-dom';
import { StyledCard } from '../../../App';
import HighlightedZoneDetails from './HighlightedZoneDetails';
import ZoneTable from './ZoneTable';
import { $houseListStore } from '../../../store/HouseListData';
import { $loginUserStore } from '../../../store/ResponseData';
import { $zoneTableDataStore } from '../../../store/ZoneTableStore';
import MainContentWrap from '../../ui/wrappers/MainContentWrap';
import {
    AppRoute,
    DEFAULT_POINT,
} from '../../../utils/const';
import { getZoneList } from './ZoneListApi';

const ZoneList = observer(() => {
    const {
        page,
        rowsPerPage,
        sort,
        zoneSearch,
    } = $zoneTableDataStore;

    useEffect(() => {
        $zoneTableDataStore.setLoadingZoneTable(true);
        const getZoneListInfo = async () => {
            const data = await getZoneList(
                page + 1,
                sort.by,
                sort.order,
                rowsPerPage,
                zoneSearch
            );
            return data;
        };
        getZoneListInfo()
            .then((res) => {
                if (!res) return;

                $zoneTableDataStore.setTableLineData(res);
            })
            .finally(() => $zoneTableDataStore.setLoadingZoneTable(false));
    }, [page, rowsPerPage, sort.by, sort.order, zoneSearch]);

    let navigate = useNavigate();
    const handleAddNewZoneClick = useCallback(() => {
        $zoneTableDataStore.setSelectedZoneData(DEFAULT_POINT);
        $zoneTableDataStore.setNewCoordinates(undefined);
        $zoneTableDataStore.setCoordinates(undefined);
        $zoneTableDataStore.setReturnInitCoordinates(false);
        $houseListStore.setSearch('');
        navigate(
            generatePath(AppRoute.ZoneNew)
        );
    }, [navigate]);

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Зонирование</h3>
                            </Grid>
                            {
                                $loginUserStore.isAdmin()
                                ? <Grid item>
                                    <IconButton onClick={handleAddNewZoneClick}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                                : null
                            }
                        </Grid>
                    </Grid>
                    <Grid item alignSelf={"flex-end"}>
                        <Grid container direction={"row"}>
                            <SearchField
                                value={zoneSearch}
                                callback={(value) => $zoneTableDataStore.setSearch(value)}
                                callbackTimeout={700}
                                style={{
                                    width: 300,
                                    marginRight: 10
                                }}
                            />
                            <Grid item>
                                <IconButton
                                    onClick={() =>
                                        $zoneTableDataStore.setShowZoneDetailes(
                                            !$zoneTableDataStore.showZoneDetailes
                                        )
                                    }
                                    style={{ height: 46 }}
                                >
                                    {$zoneTableDataStore.showZoneDetailes ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <Grid container direction={"row"} justifyContent={"space-evenly"} spacing={3}>
                        <Grid
                            item
                            xs={$zoneTableDataStore.showZoneDetailes ? 8 : 12}
                            lg={$zoneTableDataStore.showZoneDetailes ? 8 : 12}
                            sm={7}
                            md={8}
                        >
                            <ZoneTable />
                        </Grid>
                        {$zoneTableDataStore.showZoneDetailes && (
                            <Grid item xs={4} lg={4} sm={5} md={4}>
                                <HighlightedZoneDetails />
                            </Grid>
                        )}
                    </Grid>
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default ZoneList;
